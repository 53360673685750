import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import { VideoProvider } from 'contexts/video/video.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Modal from 'components/modal';
import Banner from 'sections/banner';
import CallToAction from 'sections/call-to-action';
import FAQ from 'sections/faq';
import CtaTwo from 'sections/cta-two';
import WorkFlow from 'sections/workflow';
import ExcitingFeatures from 'sections/exciting-features';
import Pricing from 'sections/pricing';
import TestimonialCard from 'sections/testimonial';

import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';
import 'react-multi-carousel/lib/styles.css';
import 'swiper/css';
import 'typeface-dm-sans';

//@see template: https://startuplanding.redq.io/
const GrenzgaengerPage = () => {
  return (
    <StickyProvider>
      <VideoProvider>
        <Layout isFrontPage={true}>
          <SEO
            description="Sie möchten wissen, was Sie in der Schweiz als Grenzgänger oder Aufenthalter in Zukunft verdienen? Jetzt online Nettolohn berechnen."
            title="Brutto Netto Rechner Schweiz für Grenzgänger, Wochenaufenthalter und Aufenthalter"
          />
          <Banner />
          <CtaTwo />
          <WorkFlow />
          <ExcitingFeatures />
          <FAQ />
          <CallToAction />
          <Modal />
        </Layout>
      </VideoProvider>
    </StickyProvider>
  );
};

export default GrenzgaengerPage;
