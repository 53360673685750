import React from 'react';
import { Box, Container } from 'theme-ui';
import Accordion from 'components/accordion/accordion';
import BlockTitle from 'components/block-title';

const accordionData = [
  {
    isExpanded: false,
    title: 'Muss ich als Grenzgänger in der Schweiz und in Deutschland Steuern bezahlen?',
    contents: (
      <div>
          Grenzgänger sind am Wohnort, also in Deutschland, steuerpflichtig. Zwischen der Schweiz und Deutschland
          besteht ein Doppelbesteuerungsabkommen. So zieht der Schweizer Arbeitgeber zwar 4,5 Prozent Quellensteuer
          von Ihrem Bruttogehalt ab, diese kann aber in Deutschland angerechnet werden.
      </div>
    ),
  },
  {
    isExpanded: true,
    title: 'Für wen lohnt es sich, den Wohnsitz in die Schweiz zu verlegen?',
    contents: (
      <div>
          Ein hohes Gehalt kann ein Grund sein, Deutschland den Rücken zu kehren. Die deutlich höheren deutschen
          Steuersätze können mit der steuerlich vorteilhafteren Schweiz, wo der durchschnittliche Steuersatz bei ca.
          12 Prozent liegt, in den meisten Fällen nicht mithalten. Insbesondere alleinstehende Grenzgänger profitieren
          von einer Einwanderung in die Schweiz, denn die steuerlichen Vorteile können enorm sein. Sobald man Familie
          hat, sieht das Ganze anders aus.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Für wen ist der Verbleib in Deutschland und das tägliche Pendeln in die Schweiz ratsamer?',
    contents: (
      <div>
          Familien mit Kleinkindern sollten sich einen Umzug in die Schweiz gründlich überlegen. Die hohen
          Lebenshaltungskosten minimieren die Steuerersparnisse. Speziell die Kindertagesbetreuung bis zum
          Kindergartenalter ist in der Schweiz sehr teuer. Zudem kennt die Schweiz keine Elternzeit und zwingt die
          Mütter nach dem Mutterschaftsurlaub von 14 Wochen zurück an den Arbeitsplatz.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Welche Bewilligung brauche ich, um als Grenzgänger in der Schweiz arbeiten zu können?',
    contents: (
      <div>
          Um in der Schweiz arbeiten zu können, benötigen Sie eine Grenzgängerbewilligung. Es ist die Aufgabe Ihres
          Arbeitgebers, die Bewilligung bei der zuständigen kantonalen Stelle in der Schweiz zu beantragen. Pendler
          erhalten eine G-Bewilligung. Diese ist bei einer unbefristeten Anstellung 5 Jahre gültig ist.
      </div>
    ),
  },
];

const FAQ = () => {
  return (
    <Box as="section" id="faq" sx={styles.accordion}>
      <Container sx={styles.accordion.container}>
        <BlockTitle
          sx={styles.accordion.blockTitle}
          tagline="FAQ"
          heading="Häufig gestellte Fragen"
        />
        <Accordion items={accordionData} />
      </Container>
    </Box>
  );
};
export default FAQ;

const styles = {
  accordion: {
    container: {
      maxWidth: '900px',
    },
    blockTitle: {
      marginBottom: [25, null, null, 65],
      textAlign: 'center',
    },
  },
};
