import {Box, Text, Flex, Container, Button, Link as A} from 'theme-ui';
import React from 'react';
import BlockTitle from 'components/block-title';
import Image from 'components/image';
import {graphql, useStaticQuery} from "gatsby";

const CtaTwo = () => {
  const illustration = useStaticQuery(graphql`
    query {
      feature: file(relativePath: { eq: "business_man.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box as="section" sx={styles.ctaTwo}>
      <Container>
        <Flex sx={styles.ctaTwo.row}>
          <Box sx={styles.ctaTwo.col}>
            <Box sx={styles.ctaTwo.illustration}>
              <Image
                  sx={styles.ctaTwo.img}
                  src={illustration.feature.childImageSharp.fluid}
                  alt="business man"
              />
            </Box>
          </Box>
          <Box sx={styles.ctaTwo.col}>
            <Box sx={styles.ctaTwo.content}>
              <BlockTitle
                sx={styles.ctaTwo.blockTitle}
                tagline="Wir helfen Ihnen"
                heading={
                  'Sie überlegen für ein Stellenangebot in die Schweiz zu ziehen?'
                }
              />
              <Text as="p" sx={styles.ctaTwo.text}>
                {
                  'Aus über 30 Jahren Berufserfahrung wissen wir, dass eine unzureichende Berechnung des Nettolohns böse Überraschungen zur Folge hat. Daher haben wir uns zusammengetan, um eine Software-Lösung zu entwickeln die Klarheit im Markt schafft.'
                }
              </Text>
              <A
                  href="https://buy.stripe.com/7sI3fLc1ycJdblKeUU"
                  target="_blank"
              >
                <Button
                    variant="primary"
                    aria-label="btn"
                >
                  Korrekte Berechnung starten
                </Button>
              </A>
            </Box>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default CtaTwo;

const styles = {
  ctaTwo: {
    paddingBottom: '100px',
    '@media(min-width: 1200px)': {
      paddingTop: '100px',
    },
    '@media(max-width:1024px)': {
      paddingBottom: 80,
    },
    row: {
      flexWrap: 'wrap',
    },
    col: {
      flex: '0 0 50%',
      '@media(max-width:1024px)': {
        flex: '0 0 100%',
      },
    },
    illustration: {
      display: ['none', 'none', 'block'],
    },
    img: {
      maxWidth: '100%',
      position: 'relative',
      borderRadius: '1%',
      right: '0px',
      bottom: '0px',
      '@media(max-width:1024px)': {
        maxWidth: '80%',
        maxHeight: '80%',
        right: 'auto',
        top: '40px',
        bottom: 'auto',
      },
    },
    content: {
      paddingLeft: '114px',
      '@media(max-width:1024px)': {
        paddingLeft: 0,
        maxWidth: 475,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      },
      '@media(max-width:575px)': {
        maxWidth: '91%',
      },
    },
    blockTitle: {
      marginTop: [50, 50, 50, 0],
      marginBottom: [20, null, null, 25],

      h3: {
        fontSize: ['24px', null, null, '36px', null, '40px'],
        lineHeight: 1.15,
        letterSpacing: ['-0.5px', null, null, '-1.5px'],
        whiteSpace: ['normal', null, null, null, null, 'pre-line'],
      },
    },
    text: {
      fontSize: [15, null, null, 18],
      lineHeight: 2.33,
      color: 'black',
      mb: [20, null, null, null, null, 3],
      whiteSpace: ['normal', null, null, null, 'pre-line'],
    },
  },
};
