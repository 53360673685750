/** @jsx jsx */
import { jsx, Box, Container } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import BlockTitle from 'components/block-title';
import Accordion from 'components/rc-collapse/collapse';
import Image from 'components/image';
import React from "react";

const data = [
  {
    id: 1,
    title: `Vollständige Berechnung deutscher Steuerpflicht`,
    contents: `Der Nettolohnrechner weist nicht nur die Abgaben in der Schweiz aus, sondern berechnet ebenso genau die Steuerpflicht in Deutschland.`,
  },
  {
    id: 2,
    title: 'Von Steuerberatern geprüft',
    contents: `Unser Nettolohnrechner wurde von spezialisierten Steuerberatern auf Herz und Nieren geprüft.`,
  },
  {
    id: 3,
    title: 'Tagesaktueller Umrechnungskurs',
    contents: `Wir verwenden den Umrechnungskurs der europäischen Zentralbank, um einen tagesaktuell genauen CHF/Euro Kurs zu gewährleisten.`,
  },
];

const ExcitingFeatures = () => {
  const illustration = useStaticQuery(graphql`
    query {
      feature: file(relativePath: { eq: "woman_calculating.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Box as="section" variant="section.excitingFeatures">
      <Container>
        <Box sx={styles.contentWrapper}>
          <Box sx={styles.illustration}>
            <Image
              sx={styles.img}
              src={illustration.feature.childImageSharp.fluid}
              alt="feature"
            />
          </Box>
          <Box>
            <Box sx={styles.rightContent}>
              <BlockTitle
                  sx={styles.heading}
                  tagline=""
                  heading="Was unterscheidet den Nettolohnrechner von anderen Rechnern am Markt?"
              />
            </Box>
            <Box sx={styles.accordionGroup}>
              {/* <Accordion items={data} /> */}
              <Accordion items={data} />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ExcitingFeatures;

const styles = {
  img: {
    borderRadius: '1%',
  },
  contentWrapper: {
    // gap: [0, 0, 0, 0, 10, 100],
    display: ['block', 'block', 'grid', 'flex', 'grid'],
    gridTemplateColumns: '1fr 1fr',
    alignItems: ['unset', 'unset', 'unset', 'unset', 'center'],
    flexDirection: ['column-reverse'],
  },
  rightContent: {
    pb: 4,
  },
  illustration: {
    display: ['none', 'none', 'block'],
    maxWidth: '90%',
  },
  heading: {
    maxWidth: [295, 295, 495, 495, 410, 500],
    textAlign: ['center', null, null, null, 'left'],
    mb: [30],
    ml: ['auto', null, null, null, 0],
    h2: {
      fontSize: ['28px', '28px', '28px', '36px', '32px', '36px', '48px'],
      lineHeight: [1.33, 1.33, 1.26],
      letterSpacing: '-1px',
    },
    img: {
      maxWidth: ['24px', '24px', '24px', '30px', '30px', '30px', '100%'],
      top: ['4px', '8px'],
    },
  },
  accordionGroup: {
    maxWidth: ['none', null, null, 600, 'none'],
  },
};
